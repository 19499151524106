var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading || !_vm.authenticatedUser
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : [
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                  attrs: { id: "producer-page" },
                },
                [
                  _vm._m(1),
                  _c("div", { staticClass: "wrapper--filled" }, [
                    _c("div", { staticClass: "platform" }, [
                      _c("h2", { staticClass: "h2" }, [_vm._v("Mailchimp")]),
                      _vm.mailchimpMarketingAudiences.length > 0
                        ? _c("div", { staticClass: "formGroup dropdown" }, [
                            _c("label", { staticClass: "formLabel" }, [
                              _vm._v("Audience"),
                            ]),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "formInput formInput--fullWidth formInput--selectCustom",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleMenuDropdown(
                                      _vm.AudienceType.Mailchimp
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getSelectedAudienceName(
                                      _vm.AudienceType.Mailchimp
                                    )
                                  )
                                ),
                                _c("icon", { attrs: { name: "arrow-down" } }),
                              ],
                              1
                            ),
                            _vm.openMenuDropDown === _vm.AudienceType.Mailchimp
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "dropdown__menu dropdown__menu--withInput",
                                  },
                                  _vm._l(
                                    _vm.mailchimpMarketingAudiences,
                                    function (audience) {
                                      return _c(
                                        "li",
                                        {
                                          key: audience.id,
                                          staticClass:
                                            "dropdown__item dropdown__item--sm",
                                          class: {
                                            isActive:
                                              audience.id ===
                                              _vm.authenticatedUser
                                                .mailchimp_audience_id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateProducerMarketingAudience(
                                                audience.id,
                                                _vm.AudienceType.Mailchimp
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(audience.name))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.mailchimpMarketingAudiences.length === 0
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn--black",
                              on: {
                                click: function ($event) {
                                  return _vm.connectAudienceType(
                                    _vm.AudienceType.Mailchimp
                                  )
                                },
                              },
                            },
                            [_vm._v("Connect Mailchimp")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn--danger",
                              on: {
                                click: function ($event) {
                                  return _vm.disconnectAudienceType(
                                    _vm.AudienceType.Mailchimp
                                  )
                                },
                              },
                            },
                            [_vm._v("Disconnect Mailchimp")]
                          ),
                    ]),
                    _c("div", { staticClass: "platform" }, [
                      _c("h2", { staticClass: "h2" }, [_vm._v("GetResponse")]),
                      _vm.getresponseMarketingAudiences.length > 0
                        ? _c("div", { staticClass: "formGroup dropdown" }, [
                            _c("label", { staticClass: "formLabel" }, [
                              _vm._v("Audience"),
                            ]),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "formInput formInput--fullWidth formInput--selectCustom",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleMenuDropdown(
                                      _vm.AudienceType.Getresponse
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getSelectedAudienceName(
                                      _vm.AudienceType.Getresponse
                                    )
                                  )
                                ),
                                _c("icon", { attrs: { name: "arrow-down" } }),
                              ],
                              1
                            ),
                            _vm.openMenuDropDown ===
                            _vm.AudienceType.Getresponse
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "dropdown__menu dropdown__menu--withInput",
                                  },
                                  _vm._l(
                                    _vm.getresponseMarketingAudiences,
                                    function (audience) {
                                      return _c(
                                        "li",
                                        {
                                          key: audience.id,
                                          staticClass:
                                            "dropdown__item dropdown__item--sm",
                                          class: {
                                            isActive:
                                              audience.id ===
                                              _vm.authenticatedUser
                                                .getresponse_campaign_id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateProducerMarketingAudience(
                                                audience.id,
                                                _vm.AudienceType.Getresponse
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(audience.name))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.getresponseMarketingAudiences.length === 0
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn--getResponse",
                              on: {
                                click: function ($event) {
                                  return _vm.connectAudienceType(
                                    _vm.AudienceType.Getresponse
                                  )
                                },
                              },
                            },
                            [_vm._v("Connect GetResponse")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn--danger",
                              on: {
                                click: function ($event) {
                                  return _vm.disconnectAudienceType(
                                    _vm.AudienceType.Getresponse
                                  )
                                },
                              },
                            },
                            [_vm._v("Disconnect GetResponse")]
                          ),
                    ]),
                    _c("div", { staticClass: "platform" }, [
                      _c("h2", { staticClass: "h2" }, [_vm._v("AWeber")]),
                      _vm.aweberMarketingAudiences.length > 0
                        ? _c("div", { staticClass: "formGroup dropdown" }, [
                            _c("label", { staticClass: "formLabel" }, [
                              _vm._v("Audience"),
                            ]),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "formInput formInput--fullWidth formInput--selectCustom",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleMenuDropdown(
                                      _vm.AudienceType.Aweber
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getSelectedAudienceName(
                                      _vm.AudienceType.Aweber
                                    )
                                  )
                                ),
                                _c("icon", { attrs: { name: "arrow-down" } }),
                              ],
                              1
                            ),
                            _vm.openMenuDropDown === _vm.AudienceType.Aweber
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "dropdown__menu dropdown__menu--withInput",
                                  },
                                  _vm._l(
                                    _vm.aweberMarketingAudiences,
                                    function (audience) {
                                      return _c(
                                        "li",
                                        {
                                          key: audience.id,
                                          staticClass:
                                            "dropdown__item dropdown__item--sm",
                                          class: {
                                            isActive:
                                              audience.id.toString() ===
                                              _vm.authenticatedUser
                                                .aweber_list_id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateProducerMarketingAudience(
                                                audience.id,
                                                _vm.AudienceType.Aweber
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(audience.name))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm.aweberMarketingAudiences.length === 0
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn--aweber",
                              on: {
                                click: function ($event) {
                                  return _vm.connectAudienceType(
                                    _vm.AudienceType.Aweber
                                  )
                                },
                              },
                            },
                            [_vm._v("Connect AWeber")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn--danger",
                              on: {
                                click: function ($event) {
                                  return _vm.disconnectAudienceType(
                                    _vm.AudienceType.Aweber
                                  )
                                },
                              },
                            },
                            [_vm._v("Disconnect AWeber")]
                          ),
                    ]),
                  ]),
                ]
              ),
            ],
      ],
      2
    ),
    _c("div", { staticClass: "fabWrapper fabWrapper--right" }, [
      _c(
        "a",
        {
          staticClass: "btn btn--primary",
          on: {
            click: function ($event) {
              return _vm.validateForm("user")
            },
          },
        },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Email Marketing")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Platforms")]),
      _c("p", [_vm._v("Select the platform you use and link your accounts.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }